import {
  PaginationContent,
  PaginationItem,
  PaginationSection,
} from '../../../styles/components/app/shared/Pagination'

import Button from '../../../styles/shared/Button'

export default function Pagination({
  currentPage,
  totalPages,
  setCurrentPage,
  get,
}: any) {
  return (
    <PaginationSection>
      <PaginationContent>
        <PaginationItem>
          <Button
            padding="5px 15px"
            disabled={currentPage === 1}
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1)
                get(currentPage - 1)
              }
            }}
          >
            Anterior
          </Button>
        </PaginationItem>
        <PaginationItem>
          {currentPage} de {totalPages === 0 ? 1 : totalPages}
        </PaginationItem>
        <PaginationItem>
          <Button
            padding="5px 15px"
            disabled={currentPage >= totalPages}
            onClick={() => {
              if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1)
                get(currentPage + 1)
              }
            }}
          >
            Próxima
          </Button>
        </PaginationItem>
      </PaginationContent>
    </PaginationSection>
  )
}
