import styled from '@emotion/styled'

export const PaginationSection = styled.nav`
  padding-block: 25px;
`

export const PaginationContent = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 5px;
  background: #1a1c1e;
`

export const PaginationItem = styled.li``
