/* eslint-disable react-hooks/exhaustive-deps */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Text,
  Box,
  Flex,
  HStack,
  Avatar,
  Badge,
} from '@chakra-ui/react'
import { ITicketMessage } from '../../../../interfaces/ITicketMessage'
import { api } from '../../../../services/api'

import Button from '../../../../styles/shared/Button'
import Form from '../../../../styles/shared/Form'
import { format } from 'date-fns'

import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export default function UserTicket({ isOpen, onClose, ticketId, get }: any) {
  const [loading, setLoading] = useState(true)

  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState<ITicketMessage[]>([])

  const getMessages = async () => {
    if (!ticketId) return

    setLoading(true)

    await api
      .get(`v1/users/tickets/${ticketId}`)
      .then((response) => {
        setMessages(response.data)

        setLoading(false)
      })
      .catch((_error) => {
        toast('Ocorreu um erro ao carregar as mensagens.', {
          type: 'error',
        })
      })
  }

  const reply = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()

    setLoading(true)

    if (!message) {
      toast('Insira a mensagem.', {
        type: 'error',
      })

      setLoading(false)
    }

    await api
      .post(`v1/users/tickets/${ticketId}`, {
        message,
      })
      .then((response) => {
        toast('Mensagem enviada com sucesso.', {
          type: 'success',
        })

        setMessage('')

        getMessages()
        get()

        setLoading(false)
      })
      .catch((_error) => {
        toast('Ocorreu um erro ao enviar a mensagem.', {
          type: 'error',
        })

        setLoading(false)
      })
  }

  useEffect(() => {
    getMessages()
  }, [ticketId])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setMessages([])
      }}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent background="#101213">
        <ModalHeader>Ticket #{ticketId}</ModalHeader>
        <ModalCloseButton />
        <Form method="POST" onSubmit={reply}>
          <ModalBody>
            <Box overflowY={'auto'} maxHeight={'250px'} padding={5}>
              {messages?.map((message, index) => (
                <Flex key={index} flexDirection={'column'} marginBottom={10}>
                  <Text>
                    {format(
                      new Date(message.created_at),
                      "dd/MM/yyyy 'às' HH:mm",
                    )}
                  </Text>
                  <HStack marginTop={3}>
                    <Avatar
                      name={`${message.user.name}`}
                      bg="cyan.500"
                      color="white"
                      src="https://bit.ly/broken-link"
                      size="lg"
                    />
                    <Box>
                      <Text>{message.user.name}</Text>
                      <Text>
                        <Badge
                          colorScheme={
                            message.user.group === 'CLIENT' ? 'gray' : 'green'
                          }
                        >
                          {(message.user.group === 'CLIENT' && 'Você') ||
                            (message.user.group === 'SUPPORT' && 'Suporte') ||
                            (message.user.group === 'ADMIN' && 'Administrador')}
                        </Badge>
                      </Text>
                    </Box>
                  </HStack>
                  <Box
                    width={'fit-content'}
                    background="#1a1c1e"
                    padding={5}
                    borderRadius={6}
                    marginTop={3}
                  >
                    <Text wordBreak="break-word">{message.message}</Text>
                  </Box>
                </Flex>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Textarea
              variant="flushed"
              placeholder="Mensagem"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              isReadOnly={loading}
              mr={3}
            />
            <Button type="submit" padding="24px" fill disabled={loading}>
              Enviar mensagem
            </Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}
