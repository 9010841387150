import Icon from '../../../styles/shared/Icon'

import { NavbarLogo } from '../../../styles/shared/Navbar'

import {
  NavbarMobileBody,
  NavbarMobileCloseButton,
  NavbarMobileContent,
  NavbarMobileHeader,
  NavbarMobileItems,
  NavbarMobileOverlay,
  NavbarMobileSection,
  NavbarMobileTitle,
  NavbarMobileItem,
} from '../../../styles/shared/NavbarMobile'

import Router from 'next/router'

export default function NavbarMobile({ isOpen, onClose }: any) {
  return (
    <NavbarMobileSection
      size="full"
      placement="left"
      isOpen={isOpen}
      onClose={onClose}
    >
      <NavbarMobileOverlay />
      <NavbarMobileContent>
        <NavbarMobileCloseButton />
        <NavbarMobileHeader>
          <NavbarLogo onClick={() => Router.push('/')}>
            <Icon className="fa-solid fa-server" /> CenturiumHost
          </NavbarLogo>
        </NavbarMobileHeader>
        <NavbarMobileBody>
          <NavbarMobileTitle>Navbar</NavbarMobileTitle>
          <NavbarMobileItems>
            <NavbarMobileItem onClick={() => Router.push('/app')}>
              <Icon className="fa-solid fa-house" /> Início
            </NavbarMobileItem>
            <NavbarMobileItem onClick={() => Router.push('/app/services')}>
              <Icon className="fa-solid fa-server" /> Serviços
            </NavbarMobileItem>
            <NavbarMobileItem onClick={() => Router.push('/app/support')}>
              <Icon className="fa-solid fa-headset" /> Atendimento
            </NavbarMobileItem>
            <NavbarMobileItem onClick={() => Router.push('/app/invoices')}>
              <Icon className="fa-solid fa-file" /> Faturas
            </NavbarMobileItem>
          </NavbarMobileItems>
        </NavbarMobileBody>
      </NavbarMobileContent>
    </NavbarMobileSection>
  )
}
