import {
  Container,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react'

import { TableCustom } from '../../../../styles/shared/Table'
import Icon from '../../../../styles/shared/Icon'
import Button from '../../../../styles/shared/Button'

import {
  UserTicketsHeader,
  UserTicketsSection,
  UserTicketsTitle,
} from '../../../../styles/components/app/shared/UserTickets'
import UserTicketsCreate from './UserTicketsCreate'
import { ITicket } from '../../../../interfaces/ITicket'
import { useEffect, useState } from 'react'
import { api } from '../../../../services/api'
import { format } from 'date-fns'
import UserTicket from './UserTicket'
import Pagination from '../Pagination'
import { toast } from 'react-toastify'

export default function UserTickets() {
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure()
  const {
    isOpen: isOpenTicket,
    onOpen: onOpenTicket,
    onClose: onCloseTicket,
  } = useDisclosure()

  const [loading, setLoading] = useState(false)

  const [tickets, setTickets] = useState<ITicket[]>([])
  const [ticketSelected, setTicketSelected] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const get = async (page: number) => {
    setLoading(true)

    await api.get(`v1/users/tickets/all/${page}`).then((response) => {
      setTickets(response.data.data)
      setTotalPages(response.data.meta.last_page)

      setLoading(false)
    })
  }

  const close = async (id: number) => {
    setLoading(true)

    await api.delete(`v1/users/tickets/${id}`).then((response) => {
      toast('Ticket fechado com sucesso.', {
        type: 'success',
      })

      get(currentPage)

      setLoading(false)
    })
  }

  useEffect(() => {
    get(1)
  }, [])

  return (
    <UserTicketsSection>
      <Container maxWidth="container.xl">
        <UserTicketsHeader>
          <UserTicketsTitle>Tickets</UserTicketsTitle>
          <Button padding="10px" onClick={onOpenCreate}>
            Abrir ticket
          </Button>
        </UserTicketsHeader>

        <TableContainer>
          <TableCustom variant="simple">
            <Thead>
              <Tr>
                <Th>Departamento</Th>
                <Th>Assunto</Th>
                <Th>Última mensagem</Th>
                <Th>Última atualização</Th>
                <Th>Status</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan={6}>
                    <Skeleton
                      startColor="#232427"
                      endColor="#1a1c1e"
                      height="30px"
                      borderRadius={0}
                    />
                  </Td>
                </Tr>
              ) : tickets.length > 0 ? (
                tickets?.map((ticket, index) => (
                  <Tr key={index}>
                    <Td>
                      {(ticket.department === 'SUPPORT' && 'Geral') ||
                        (ticket.department === 'BILLING' &&
                          'Suporte Financeiro') ||
                        (ticket.department === 'TECHNICAL' &&
                          'Suporte Técnico')}
                    </Td>
                    <Td>
                      {ticket.subject.substring(0, 20)}
                      {ticket.subject.length > 20 ? '...' : ''}
                    </Td>
                    <Td>
                      {ticket.userTicketMessage[0]?.message.substring(0, 20)}
                      {ticket.userTicketMessage[0]?.message.length > 20
                        ? '...'
                        : ''}
                    </Td>
                    <Td>
                      {ticket.updated_at
                        ? format(
                            new Date(ticket.updated_at),
                            "dd/MM/yyyy 'às' HH:mm",
                          )
                        : '...'}
                    </Td>
                    <Td>
                      {(ticket.status === 'OPEN' && 'Aberto') ||
                        (ticket.status === 'ANSWERED_BY_SUPPORT' &&
                          'Respondido') ||
                        (ticket.status === 'ANSWERED_BY_USER' &&
                          'Aguardando resposta') ||
                        (ticket.status === 'CLOSED' && 'Fechado')}
                    </Td>
                    <Td display="flex">
                      <Button
                        padding="3px 10px"
                        disabled={loading}
                        onClick={() => {
                          setTicketSelected(ticket.id)
                          onOpenTicket()
                        }}
                        margin="0 5px 0 0"
                      >
                        <Icon className="fa-solid fa-eye" size="14px" />
                      </Button>
                      <Button
                        padding="3px 10px"
                        disabled={loading}
                        onClick={() => close(ticket.id)}
                      >
                        <Icon className="fa-solid fa-close" size="14px" />
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6}>Você não tem nenhum ticket.</Td>
                </Tr>
              )}
            </Tbody>
          </TableCustom>
        </TableContainer>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          get={get}
        />
      </Container>
      <UserTicketsCreate
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
        get={get}
      />
      <UserTicket
        isOpen={isOpenTicket}
        onClose={onCloseTicket}
        ticketId={ticketSelected}
        get={get}
      />
    </UserTicketsSection>
  )
}
