import { Table } from '@chakra-ui/react'

import styled from '@emotion/styled'

export const TableCustom = styled(Table)`
  thead {
    tr,
    th {
      color: #0bc5ea;
      border: 0;
      border-radius: 12px;
    }
  }
  tbody {
    background: #1a1c1e;

    tr,
    td {
      border: 0;
    }
  }
`
