import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react'
import { api } from '../../../../services/api'

import Button from '../../../../styles/shared/Button'
import Form from '../../../../styles/shared/Form'

import React, { useState } from 'react'
import { toast } from 'react-toastify'

export default function UserTicketsCreate({ isOpen, onClose, get }: any) {
  const [loading, setLoading] = useState(false)

  const [department, setDepartment] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const create = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()

    setLoading(true)

    if (!department || !subject || !message) {
      toast('Preencha todos os campos.', {
        type: 'error',
      })

      return setLoading(false)
    }

    await api
      .post('v1/users/tickets', {
        department,
        subject,
        message,
      })
      .then((response) => {
        toast('Ticket criado com sucesso.', {
          type: 'success',
        })

        setDepartment('')
        setSubject('')
        setMessage('')

        onClose()
        get()

        setLoading(false)
      })
      .catch((_error) => {
        toast('Ocorreu um erro ao criar o ticket.', {
          type: 'error',
        })

        setLoading(false)
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent background="#101213">
        <ModalHeader>Abrir ticket</ModalHeader>
        <ModalCloseButton />
        <Form method="POST" onSubmit={create}>
          <ModalBody>
            <Select
              variant="flushed"
              placeholder="Selecione o departamento"
              value={department}
              onChange={(event) => setDepartment(event.target.value)}
              isReadOnly={loading}
            >
              <option value="SUPPORT">Geral</option>
              <option value="BILLING">Suporte Financeiro</option>
              <option value="TECHNICAL">Suporte Técnico</option>
            </Select>
            <Input
              variant="flushed"
              type="text"
              placeholder="Assunto"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              isReadOnly={loading}
              mt={4}
            />
            <Textarea
              variant="flushed"
              placeholder="Mensagem"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              isReadOnly={loading}
              mt={4}
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" fill disabled={loading}>
              Abrir ticket
            </Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}
