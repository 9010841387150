import styled from '@emotion/styled'

export const UserTicketsSection = styled.section`
  padding-block: 50px;
`

export const UserTicketsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const UserTicketsTitle = styled.h2`
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
`
