import {
  Avatar,
  Container,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  SkeletonCircle,
  useDisclosure,
} from '@chakra-ui/react'

import Icon from '../../../styles/shared/Icon'
import {
  NavbarItem,
  NavbarItems,
  NavbarMobileItem,
} from '../../../styles/shared/Navbar'

import {
  NavbarContent,
  NavbarSection,
} from '../../../styles/components/app/shared/Navbar'

import Router from 'next/router'
import { useContext } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'
import Recharge from './Recharge'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import Profile from './Profile'
import NavbarMobile from './NavbarMobile'

export default function Navbar() {
  const { user } = useContext(AuthContext)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isOpenRecharge,
    onOpen: onOpenRecharge,
    onClose: onCloseRecharge,
  } = useDisclosure()

  const {
    isOpen: isOpenProfile,
    onOpen: onOpenProfile,
    onClose: onCloseProfile,
  } = useDisclosure()

  return (
    <NavbarSection>
      <Container maxWidth="container.xl">
        <NavbarContent>
          <Image
            src="/logo-white.png"
            width="auto"
            height="50px"
            alt="CenturiumHost"
            style={{ cursor: 'pointer' }}
            onClick={() => Router.push('/')}
            onDragStart={(event) => event.preventDefault()}
          />
          <NavbarItems>
            <NavbarItem onClick={() => Router.push('/app')}>
              <Icon className="fa-solid fa-house" /> Início
            </NavbarItem>
            <NavbarItem onClick={() => Router.push('/app/services')}>
              <Icon className="fa-solid fa-server" /> Serviços
            </NavbarItem>
            <NavbarItem onClick={() => Router.push('/app/support')}>
              <Icon className="fa-solid fa-headset" /> Atendimento
            </NavbarItem>
            <NavbarItem onClick={() => Router.push('/app/invoices')}>
              <Icon className="fa-solid fa-file" /> Faturas
            </NavbarItem>
          </NavbarItems>
          <NavbarMobileItem onClick={onOpen}>
            <Icon className="fa-solid fa-bars" />
          </NavbarMobileItem>
          <Menu>
            <MenuButton>
              {user ? (
                <Avatar
                  name={`${user?.name}`}
                  bg="cyan.500"
                  color="white"
                  src="https://bit.ly/broken-link"
                />
              ) : (
                <SkeletonCircle
                  startColor="#232427"
                  endColor="#1a1c1e"
                  size={'50px'}
                />
              )}
            </MenuButton>
            <MenuList background="#1a1c1e">
              <MenuItem onClick={onOpenProfile}>Configurações</MenuItem>
              <MenuItem onClick={onOpenRecharge}>Recarregar</MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  Cookies.remove('centurium.token')
                  Router.push('/auth/login')

                  toast('Você saiu da sua conta com sucesso!', {
                    type: 'success',
                  })
                }}
              >
                Sair
              </MenuItem>
            </MenuList>
          </Menu>
        </NavbarContent>
      </Container>
      <Recharge isOpen={isOpenRecharge} onClose={onCloseRecharge} />
      <Profile isOpen={isOpenProfile} onClose={onCloseProfile} />
      <NavbarMobile isOpen={isOpen} onClose={onClose} />
    </NavbarSection>
  )
}
